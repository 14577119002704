<template>
    <v-layout row justify-end>
        <v-dialog v-model="dialog" scrollable max-width="900px">
            <template v-slot:activator="{ on }">
                <button v-on="on"
                        class="
                        btn-primary
                        v-btn--largee
                        ma-0
                        v-btn--large v-btn--round
                        white--text
                        v-btn">
                    <i class="mr-3 fal fa-plus"></i>
                    Gerenciar depoimentos
                </button>
            </template>
            <v-card>
                <v-card-title class="headline">
                    <h1>Depoimentos</h1>
                    <a href="#"></a>
                    <v-btn class="close-modal" @click="dialog = false">
                        <i class="fal fa-times"></i>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 500px">
                    <v-layout w100 mobile-r-column d-flex mobile-modal-campaign>
                        <v-flex md6
                                xs12
                                class="pt-4 border-horizontal display-flex f-d-column">
                            <v-btn flat
                                   round
                                   color="white"
                                   class="d-flex ml-auto btn btn-primary p-425-0-25 px-5 mb-2"
                                   large
                                   @click="create">
                                <i class="far fa-plus mr-2"></i>Novo depoimento
                            </v-btn>
                            <div class="list-scroll">
                                <div class="box-step-modal flex"
                                     :class="{ active: selectedTestimonyId === testimony.id }"
                                     @click="selectTestimony(testimony.id)"
                                     v-for="testimony in testimonyList"
                                     :key="testimony.id">
                                    <div class="mr-3">
                                        <TestimonyInstitutionImage :testimony="testimony"
                                                                   :alt="''" />
                                    </div>
                                    <div class="flex-center">
                                        <div class="name-testimony">{{ testimony.name }}</div>
                                    </div>
                                </div>
                            </div>
                        </v-flex>
                        <v-flex class="pt-3 box-heart-father"
                                md6
                                xs12
                                :class="{ active: showForm }">
                            <div class="list-scroll">
                                <v-flex px-3>
                                    <v-flex class="upload-box-profile-father testimony" shrink>
                                        <div class="upload-image"
                                             id="preview"
                                             ref="preview"
                                             :style="'backgroundImage:' + getImage()">
                                            <label for="upload-institution">
                                                <i class="fas fa-camera-alt"></i>
                                            </label>
                                            <label for="upload-institution"
                                                   class="do-upload"
                                                   v-show="testimonyVolunteering.file.path == ''">
                                                <i class="fal fa-cloud-upload"></i>Foto
                                            </label>
                                            <input type="file"
                                                   id="upload-institution"
                                                   accept="image/*"
                                                   @change="fileChanged"
                                                   ref="upload_institution" />
                                        </div>
                                    </v-flex>
                                    <InputText ref="nameInput"
                                               type="text"
                                               textLabel="Nome"
                                               v-model="testimonyVolunteering.name"
                                               labelCharLimit="30"
                                               invalidInputMessage="Preencha o nome" />
                                    <InputText ref="roleInput"
                                               type="text"
                                               textLabel="Cargo"
                                               v-model="testimonyVolunteering.role"
                                               labelCharLimit="50"
                                               invalidInputMessage="Preencha o cargo" />
                                    <InputText ref="cityInput"
                                               type="text"
                                               textLabel="Cidade"
                                               v-model="testimonyVolunteering.city"
                                               labelCharLimit="50"
                                               invalidInputMessage="Preencha a cidade" />
                                    <InputText ref="ufInput"
                                               type="text"
                                               textLabel="UF"
                                               v-model="testimonyVolunteering.uf"
                                               labelCharLimit="2"
                                               invalidInputMessage="Preencha a UF" />
                                    <div class="t-area-heart-step">
                                        <label>Descreva o depoimento</label>
                                        <InputTextArea ref="textInput"
                                                       v-model="testimonyVolunteering.testimony"
                                                       labelAreaCharLimit="300"
                                                       invalidInputMessage="Preencha o depoimento"></InputTextArea>
                                    </div>
                                </v-flex>
                            </div>
                            <v-flex class="align-center justify-space-between footer-step-modal">
                                <v-btn flat
                                       round
                                       color="white"
                                       @click="showForm = !showForm"
                                       class="btn-back-modal d-flex theme--light btn-default px-0 ma-0 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text"
                                       large>
                                    <i class="fal fa-arrow-left"></i>
                                </v-btn>
                                <v-btn flat
                                       round
                                       color="white"
                                       class="d-flex btn btn-red px-5"
                                       large
                                       @click="openConfirmDeleteTestimonyModal(testimonyVolunteering.id)"
                                       :disabled="institutionId == 0">Excluir</v-btn>
                                <v-btn flat
                                       round
                                       color="white"
                                       class="d-flex btn btn-primary px-5"
                                       large
                                       @click="isValid"
                                       :disabled="institutionId == 0">Salvar</v-btn>
                            </v-flex>
                        </v-flex>
                    </v-layout>
                    <AucAlert ref="confirmationComponent"
                              title="Você tem certeza que deseja excluir esse depoimento?"
                              confirmButtonText="Sim"
                              cancelButtonText="Não"
                              @confirm="remove(selectedTestimonyId)"
                              :disabled="institutionId == 0" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
    import AucAlert from "@/components/AucAlert.vue";
    import TestimonyInstitutionImage from "@/components/TestimonyInstitutionImage.vue";
    import TestimonyVolunteering from "@/scripts/models/testimonyVolunteering.model";
    import VolunteeringService from "@/scripts/services/volunteering.service";
    import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
    export default {
        extends: ValidatableComponent,
        components: {
            AucAlert,
            TestimonyInstitutionImage,
        },
        props: {
            value: Array,
            volunteeringId: 0,
        },
        data() {
            return {
                volunteeringService: new VolunteeringService(),
                testimonyVolunteering: new TestimonyVolunteering(),
                testimonyList: [],
                selectedTestimonyId: null,
                dialog: false,
                showForm: false,
            };
        },
        created() {
            this.onValidationSuccessMethod = this.validationSuccess;
            this.validationMethod = this.validate;
        },
        watch: {
            dialog(v) {
                if (v) {
                    this.create();
                }
            },
        },
        methods: {
            openConfirmDeleteTestimonyModal(id) {
                if (id != 0) {
                    this.selectedTestimonyId = id;
                    this.$refs.confirmationComponent.open();
                }
            },
            listByVolunteeringId() {
                this.volunteeringService.listTestimoniesByVolunteeringId(
                    this.volunteeringId
                ).then((data) => {
                    this.listByVolunteeringIdCallback(data);
                });
            },
            listByVolunteeringIdCallback(data) {
                this.testimonyList = data;
                this.$emit("input", data);
            },
            selectTestimony(testimonyId) {
                let testimony = this.testimonyList.find(
                    (testimony) => testimony.id == testimonyId
                );
                this.selectedTestimonyId = testimonyId;
                this.copy(testimony);
            },
            copy(testimony) {
                this.testimonyVolunteering = new TestimonyVolunteering();
                this.testimonyVolunteering.id = testimony.id;
                this.testimonyVolunteering.institutionId = testimony.institutionId;
                this.testimonyVolunteering.name = testimony.name;
                this.testimonyVolunteering.role = testimony.role;
                this.testimonyVolunteering.testimony = testimony.testimony;
                this.testimonyVolunteering.city = testimony.city;
                this.testimonyVolunteering.uf = testimony.uf;
                this.testimonyVolunteering.fileId = testimony.fileId;
                this.testimonyVolunteering.imageContent = testimony.imageContent;
                this.testimonyVolunteering.file.id = testimony.file.id;
                this.testimonyVolunteering.file.userId = testimony.file.userId;
                this.testimonyVolunteering.file.name = testimony.file.name;
                this.testimonyVolunteering.file.path = testimony.file.path;
                this.testimonyVolunteering.file.size = testimony.file.size;
                this.testimonyVolunteering.file.extension = testimony.file.extension;
                this.testimonyVolunteering.file.origin = testimony.file.origin;
                this.testimonyVolunteering.file.publicId = testimony.file.publicId;
            },
            create() {
                this.listByVolunteeringId()
                this.selectedTestimonyId = 0;
                this.testimonyVolunteering = new TestimonyVolunteering();
                this.$refs.preview.style.backgroundImage = "";
            },
            getImage() {
                if (
                    this.testimonyVolunteering == null ||
                    this.testimonyVolunteering.id == 0
                )
                    return "";
                return "url(" + this.testimonyVolunteering.imageContent + ")";
            },
            fileChanged(event) {
                var file = event.target.files[0];
                let reader = new FileReader();
                this.testimonyVolunteering.file.name = file.name;
                reader.onload = function () {
                    this.testimonyVolunteering.file.path = reader.result;
                    this.testimonyVolunteering.imageContent = reader.result;
                    this.$refs.preview.style.backgroundImage = "url(" + reader.result + ")";
                }.bind(this);
                reader.readAsDataURL(file);
            },
            save() {
                this.testimonyVolunteering.volunteeringId = this.volunteeringId;
                this.volunteeringService.saveTestimony(
                    this.testimonyVolunteering
                ).then(() => { this.saveCallback() });
            },
            saveCallback(data) {
                this.listByVolunteeringId();
            },
            remove(selectedTestimonyId) {
                this.volunteeringService.deleteTestimony(
                    selectedTestimonyId
                ).then(() => this.removeCallback());
            },
            removeCallback() {
                this.listByVolunteeringId();
                this.create();
            },
            validate(performMethods) {
                if (
                    this.testimonyVolunteering.file == null ||
                    this.testimonyVolunteering.file.path == ""
                ) {
                    this.$store.commit(
                        "SET_SNACKBAR_MESSAGE",
                        "Escolha uma imagem para o depoimento"
                    );
                    return false;
                }
                return this.validateComponents(performMethods);
            },
            validationSuccess() {
                this.save();
            },
        },
    };
</script>